<template>
  <md-field>
    <md-cell-item title="七氟丙烷全淹没灭火系统" arrow @click="handleClick(3)" />
    <md-cell-item title="IG541 混合气体全淹没灭火系统" arrow @click="handleClick(4)" />
    <md-cell-item title="热气溶胶预制全淹没灭火系统" arrow @click="handleClick(5)" />
    <md-cell-item title="二氧化碳全淹没灭火系统" arrow @click="handleClick(2)" />
    <md-cell-item title="干粉全淹没灭火系统" arrow @click="handleClick(1)" />
    <md-cell-item title="建筑灭火器配置计算" arrow @click="handleClick(6)" />
  </md-field>
</template>

<script>
export default {
  methods: {
    handleClick(id) {
      this.$router.push({ name: 'calc', params: { id } })
    },
  },
}
</script>
